<template>
  <paid-main :key="reRenderComponentKey" />
</template>

<script>
import PaidMain from './index.vue'
import config from '../config'

export default {
  name: 'Paid',
  components: {
    PaidMain,
  },
  computed: {
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },

  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
